import { createTheme } from "@material-ui/core/styles";
// import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/orange";

export default createTheme({
  // MYWAY
  paper: {
    tertiary: {
      ///hausify hellgrau f2f2f2
      main: "#fff",
      text: "#000",
    },
  },
  palette: {
    primary: {
      ///hausify blau 00a2c7
      main: "#636366",
    },
    secondary: {
      ///hausify orange FCC332 (zb Head-Zeile Dropdown)
      // light
      // dark
      main: "#FF0052",
      contrastText: "#fff",
    },
    notification: {
      ticketBadge: {
        // The number configures the shade
        main: green[500],
        text: "#fff",
      },
    },
    importNotification: {
      main: "#636366",
      text: "#fff",
    },
    ticketsNotification: {
      ///hausify orange FCC332 (zb Meine Verwaltung)
      main: "#636366",
      text: "#fff",
    },
    estateNotification: {
      ///hausify rosa e3cfde
      main: "#636366",
      contrastText: "#000000",
    },
    postingsNotification: {
      ///hausify blau 00a2c7
      main: "#FF0052",
      text: "#fff",
    },

    documentsNotification: {
      main: "#fff",
      contrastText: "#000000",
      text: "#000000",
    },
    explainerBox: {
      main: "#636366",
    },
  },
  typography: {
    fontFamily: ["Rubik", "Lato", "serif"].join(","),
  },
});
